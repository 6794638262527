import React from 'react';
import indiaJoyGroupImg from '../../Assets/images/indiaJoyGroupImg.webp';
import telanganaLogo from '../../Assets/images/telanganaLogo.webp';
import tvagaLogo from '../../Assets/images/tvagaLogo.webp';
import {IndustrySupport} from '../Home/industrySupport';
import ministerylogo from '../../Assets/images/ecosystemPartners/ministrylogo.jpg';

export const Agenda = () => {
    return (
        <div className="background-container agendaFrame">
            <div className='indiaJoy__join__container'>
                <div className='indiaJoy__tribeSection'>
                <h2>JOIN THE INDIAJOY TRIBE -</h2>
                <h2>EXPERIENCE THE "INDIA" JOY</h2>
                </div>
                <div className="agenda-content" data-aos="fade-left" data-aos-delay="100">
                    <div className="indiaJoy__leftSection">
                        <h2>A PLATFORM FOR YOU - BROUGHT TO YOU BY US</h2>
                        <p>
                            IndiaJoy brings together all things entertainment curated by the passionate souls working in the digital entertainment industry.
                            Our culture is an experience that our exhibitors, attendees, partners, and event team have built over the years- making each edition stronger, memorable, and richer in every aspect.
                        </p>
                        <button className="explore-button1">
                            {/* <Link to="/register" className='nav-link'>BE AT INDIAJOY 2024</Link> */}
                            <a href="https://events.tecogis.com/indiajoy2024/select-pass" className='nav-link' target="_blank">BE AT INDIAJOY 2024</a>
                            </button>
                    </div>
                    <div className="indiaJoy__secondSection" data-aos="fade-right" data-aos-delay="100">
                        <img className='groupImage' src={indiaJoyGroupImg} alt="" style={{ maxWidth: '100%', height: 'auto' }} loading='lazy'/>
                    </div>
                </div>
            </div>

            <IndustrySupport />
            
            <div className="agenda-title indiaJoy__topTitle" data-aos="fade-right" data-aos-delay="100">
                AN INITIATIVE BY
            </div>
            <div className='logos'>
                <div className="telanganaLogo d-flex justify-content-center" data-aos="fade-right" data-aos-delay="100">
                    <img src={telanganaLogo} alt="" loading='lazy'/>
                    <img src={tvagaLogo} alt="" loading='lazy'/>
                    <img src={ministerylogo} alt="" loading='lazy'/>
                </div>
            </div>
        </div>

    )
}