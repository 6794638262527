import React, { useEffect, useState } from "react";
import Menu from "../Home/menu";
import { Footer } from "../Home/footer";
import { BuyTicket } from "../Contact/buyTicket";
import EmailIcon from "../../Assets/images/email.webp";
import PhoneIcon from "../../Assets/images/phone.webp";
import { sendConnectUserRequest } from "../utils";

export const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    id: "",
    name: "",
    phone: "",
    message: "",
    regarding: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sucessMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    // if (!formData.id) newErrors.id = "ID is required.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.message) newErrors.message = "Message is required.";
    if (!formData.regarding)
      newErrors.regarding = "Regarding field is required.";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    console.log("Form data before validation:", formData);

    const validationErrors = validate();
    // console.log(validationErrors);
    console.log(Object.keys(validationErrors).length);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      // return;
    } else {
      setErrors({});
      console.log("Form data after validation:", formData);

      try {
        // Call the API function with formData
        const result = await sendConnectUserRequest(formData);

        // Log the successful result to the console
        console.log("Form submitted successfully:", result);
        setSuccessMsg("Data submitted successfully");
        setError("");
        // Clear errors and form data on successful submission
        setErrors({});
        setFormData({
          email: "",
          id: "",
          name: "",
          phone: "",
          message: "",
          regarding: "",
        });
      } catch (error) {
        setSuccessMsg("");
        // Log any errors to the console
        console.error("Submission failed:", error);
        if (error) {
          // const errorData = error.error;
          // setError(errorData);
          setError(error.message || "An unknown error occurred.");
        } else {
          setError("An unknown error occurred.");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Menu />
      <div className="indiaJoy__contact__wraper">
        <div className="indiaJoy__contact">
          <div className="indiaJoy__contact__left">
            <h2 data-aos="fade-right" data-aos-delay="100">
              Get <br /> in <br /> touch
            </h2>
            <div className="indiaJoy__contact__section">
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Sheik Khaja Vali</h3>
                  <p className="formdetailstx">
                    Program Director and Initiatives
                  </p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>khaja@tvaga.org</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Savitri Hari</h3>
                  <p className="formdetailstx">
                    Sponsorships, Program management, and Conference
                  </p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>savitri@tvaga.org</p>
                    </div>
                    <div className="d-flex">
                      <img src={PhoneIcon} alt="PhoneIcon" loading="lazy" />
                      <p>+91 6300253002 </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Syed Shakeer</h3>
                  <p className="formdetailstx">Expo and Partnerships</p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>shakeer@tvaga.org</p>
                    </div>
                    <div className="d-flex">
                      <img src={PhoneIcon} alt="PhoneIcon" loading="lazy" />
                      <p>+91 89198 34883</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Taruni P</h3>
                  <p className="formdetailstx">
                    Workshops and Speaking Opportunities
                  </p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>taruni@tvaga.org </p>
                    </div>
                    <div className="d-flex">
                      <img src={PhoneIcon} alt="PhoneIcon" loading="lazy" />
                      <p>+91 9381337286</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Debomita Dey</h3>
                  <p className="formdetailstx">Marketing and PR</p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>marketing@tvaga.org</p>
                    </div>
                    <div className="d-flex">
                      <img src={PhoneIcon} alt="PhoneIcon" loading="lazy" />
                      <p>+919804795338</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Amitha Reddy</h3>
                  <p className="formdetailstx">Registrations</p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>connect@tvaga.org</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="indiaJoy__contact__items1"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="indiaJoy__contact__detailsInnerChild">
                  <h3>Ritto Prabhu</h3>
                  <p className="formdetailstx">Expo and Outreach</p>
                  <div className="indiaJoy__contact__inner">
                    <div className="d-flex">
                      <img src={EmailIcon} alt="EmailIcon" loading="lazy" />
                      <p>ritto.prabhu@vfxsummit.in </p>
                    </div>
                    {/* <div className="d-flex">
                      <img src={PhoneIcon} alt="PhoneIcon" loading="lazy"/>
                      <p>+91 9381337286</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="indiaJoy__contact__right"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          {/* <form onSubmit={handleSubmit}> */}
          <div className="indiaJoy__contact__formDetails">
            {/* <div className="indiaJoy__contact__formDetails1">
              <label>Name</label>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="NAME"
                style={{ borderColor: errors.name ? "red" : "initial" }}
              />
              {errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
              <label>E-mail</label>
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="E-MAIL"
                style={{ borderColor: errors.email ? "red" : "initial" }}
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
              <label>Phone</label>
              <input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="PHONE"
                style={{ borderColor: errors.phone ? "red" : "initial" }}
              />
              {errors.phone && (
                <div className="error-message">{errors.phone}</div>
              )}
            </div>
            <div className="indiaJoy__contact__formDetails2">
              <label>Select</label>
              <select
                name="regarding"
                value={formData.regarding}
                onChange={handleChange}
                style={{ borderColor: errors.regarding ? "red" : "initial" }}
              >
                <option value="">Select...</option>
                <option>Expo</option>
                <option>Speaking Opportunities</option>
                <option>Marketing</option>
                <option>Partnerships</option>
                <option>Media Partnerships</option>
                <option>Sponsorships</option>
                <option>Student registrations</option>
                <option>Registration queries</option>
              </select>
              {errors.regarding && (
                <div className="error-message">{errors.regarding}</div>
              )}
              <label>Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="MESSAGE"
                style={{ borderColor: errors.message ? "red" : "initial" }}
              ></textarea>
              {errors.message && (
                <div className="error-message">{errors.message}</div>
              )}
            </div> */}

            <iframe
              src="https://api.leadconnectorhq.com/widget/form/3jy32kTotDcFzcRHyYxz"
              style={{width:'100%', height:'100%'}}              
              id="inline-3jy32kTotDcFzcRHyYxz"
              // data-layout="{'id':'INLINE'}"
              data-trigger-type="alwaysShow"
              data-trigger-value=""
              data-activation-type="alwaysActivated"
              data-activation-value=""
              data-deactivation-type="neverDeactivate"
              data-deactivation-value=""
              data-form-name="Conatct US"
              data-height="420"
              // data-layout-iframe-id="inline-3jy32kTotDcFzcRHyYxz"
              // data-form-id="3jy32kTotDcFzcRHyYxz"
              title="Conatct US"
            ></iframe>
            
          </div>
          {/* <button
            // type="submit"
            className={`submit-button ${isSubmitting ? "submitting" : ""}`}
            onClick={handleSubmit}
          >
            Submit
          </button>

          {error && (
            <div
              className="email-error"
              style={{ marginBottom: 10, fontSize: 12 }}
            >
              {error}
            </div>
          )}
          {sucessMsg && (
            <div
              className="email-success"
              style={{ marginBottom: 10, fontSize: 12 }}
            >
              {sucessMsg}
            </div>
          )} */}
          {/* </form> */}
        </div>
      </div>
      <BuyTicket />
      <Footer />
    </div>
  );
};
