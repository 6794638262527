import React, { useEffect, useRef, useState } from 'react';
import indiaJoyTitle from '../../Assets/images/title.svg';
import xLogo from '../../Assets/images/xLogo.svg';
import linkLogo from '../../Assets/images/linkd.svg';
import instaLogo from '../../Assets/images/insta.svg';
import metaLogo from '../../Assets/images/meta.svg';
import youtubeLogo from '../../Assets/images/youtube.svg';
import Arrow from '../../Assets/images/arrow.svg';
import Popup from './popup';
import videoSource from '../../Assets/images/background_video.mp4';
import amdbannerlogo from '../../Assets/images/AMDbannerlogo.png';
import { Link } from 'react-router-dom';

export const Banner = () => {
  const videoRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const onOpenModal = () => {
    setPopupOpen(true);
  };

  const onCloseModal = () => {
    setPopupOpen(false);
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        // Autoplay was prevented
        console.error('Autoplay was prevented:', error.message);
      });
    }
  };


  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch(error => {
          // Autoplay was prevented
          console.error('Autoplay was prevented:', error.message);
        });
      }
    };

    // Play video on component mount
    playVideo();

    // Optional: Pause video on component unmount (cleanup)
    return () => {
      if (video) {
        video.pause();
      }
    };
  });


  return (
    <div className='indiaJoy__banner__container'>
      <div className='indiaJoy__banner__section'>
        <div className='video-overlay'></div>

        <video
          ref={videoRef}
          type="video/mp4"
          className='indiaJoy__bannerInfo'
          src={videoSource}
          controls={false}
          autoPlay={true} // Set autoPlay to false 
          muted                     
        />

        <div className='indiaJoy__banner__detailsSection'>
          <div className='indiaJoy__banner__details'>
            <div className='customStyleCon customStyleCon--remove--flex' data-aos="fade-right" data-aos-delay="100">
            <img src={indiaJoyTitle} alt='indiaJoyTitle'  loading="lazy" style={{verticalAlign: 'middle'}} /> Powered By <img src={amdbannerlogo} loading="lazy" style={{width:90, height:20, verticalAlign: 'middle'}} />
            {/* <span className='indiaJoy__banner__content customStyle'>Powered By <img src={amdbannerlogo} loading="lazy" style={{width:90}} /></span> */}
            </div>
            <p className='indiaJoy__banner__content' data-aos="fade-left" data-aos-delay="200" ></p>
            <p className='indiaJoy__banner__content' data-aos="fade-left" data-aos-delay="200">Asia’s premier Media and <br />Entertainment Congregation</p>
            <p className='indiaJoy__banner__subText' data-aos="fade-left" data-aos-delay="300"><span>13 - 17</span>November</p>
            <p className='indiaJoy__banner__text' data-aos="fade-left" data-aos-delay="300">HICC, Novotel, Hyderabad</p>
            <button className='indiaJoy__banner__btn' data-aos="fade-left" data-aos-delay="400" onClick={playVideo}><a href="https://events.tecogis.com/indiajoy2024/select-pass" className='nav-link' target="_blank">Book Now</a></button>
          </div>
          <div className='indiaJoy__play__btn' onClick={onOpenModal}>
            Play <img src={Arrow} alt='Arrow' loading="lazy"/>
          </div>
          <Popup open={popupOpen} onClose={onCloseModal} />
        </div>

        <div className='indiaJoy__rightPanel__container'>
          <div className='indiaJoy__rightPanel'>
            <div className='indiaJoy__rightPanel__section' data-aos="fade-left" data-aos-delay="100">
              <a href="https://twitter.com/indiajoyin?lang=en" target="_blank" rel="noopener noreferrer">
                <img src={xLogo} alt='xLogo' style={{position:'relative',right:'7px'}} loading="lazy"/>
              </a>
            </div>
            <div className='indiaJoy__rightPanel__section' data-aos="fade-left" data-aos-delay="200">
              <a href="https://www.linkedin.com/company/indiajoy/" target="_blank" rel="noopener noreferrer">
                <img src={linkLogo} alt='linkLogo' loading="lazy"/>
              </a>
            </div>
            <div className='indiaJoy__rightPanel__section' data-aos="fade-left" data-aos-delay="300">
              <a href="https://www.instagram.com/indiajoyfestival/" target="_blank" rel="noopener noreferrer">
                <img src={instaLogo} alt='instaLogo' loading="lazy"/>
              </a>
            </div>
            <div className='indiaJoy__rightPanel__section' data-aos="fade-left" data-aos-delay="400">
              <a href="https://www.facebook.com/Indiajoy.in/" target="_blank" rel="noopener noreferrer">
                <img src={metaLogo} alt='metaLogo' loading="lazy"/>
              </a>
            </div>
            <div className='indiaJoy__rightPanel__section' data-aos="fade-left" data-aos-delay="500">
              <a href="https://www.youtube.com/channel/UCe8cEAMgMZbqS2cf8t4_JQg" target="_blank" rel="noopener noreferrer">
                <img src={youtubeLogo} alt='youtubeLogo' loading="lazy"/>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
