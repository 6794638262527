import React from 'react';
import nagaAshwinImg from '../../Assets/images/nagaAshwin.webp';
import srinivasMohan from '../../Assets/images/srinivasMohan.webp';
import samitShetty from '../../Assets/images/samitShetty.webp';
import senthilKumar from '../../Assets/images/senthilKumar.webp';
import agnesKim from '../../Assets/images/agnesKim.webp';
import seanSohn from '../../Assets/images/seanSohn.webp';
import jamieBriens from '../../Assets/images/jamieBriens.webp';
import sophieVo from '../../Assets/images/sophieVo.webp';
import { FaWikipediaW } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { Link } from 'react-router-dom';
import wikipedia from '../../Assets/images/wikipediawhite.svg';

export const Speakers = () => {
    const speakers = [
        {
            name: "Nag Ashwin",
            title: "Indian film director and screenwriter",
            image: nagaAshwinImg,
            links: [
                { type: "wiki", url: "https://en.wikipedia.org/wiki/Nag_Ashwin" }
            ]
        },
        {
            name: "Agnes Kim",
            title: "Director at Xbox",
            image: agnesKim,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/agnes-kim/" }
            ]
        },
        {
            name: "Srinivas Mohan",
            title: "Indian visual effects supervisor",
            image: srinivasMohan,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/srinivas-mohan/" }
            ]
        },
        {
            name: "Sean Sohn",
            title: "CEO of Krafton India, Head of India Div. at Krafton.",
            image: seanSohn,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/sean-hyunil-sohn-16" }
            ]
        },
        {
            name: "Samit Shetty",
            title: "Sr. Technical Solutions Specialist - Media & Entertainment at Autodesk",
            image: samitShetty,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/samit-shetty/" }
            ]
        },
        {
            name: "Senthil Kumar",
            title: "Indian cinematographer",
            image: senthilKumar,
            links: [
                { type: "wiki", url: "https://en.wikipedia.org/wiki/K._K._Senthil_Kumar" }
            ]
        },        
        {
            name: "Jamie Briens",
            title: "Director of Digital Transformation of Basilic Fly Studio",
            image: jamieBriens,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/jamiebriens/" }
            ]
        },
        {
            name: "Sophie Vo",
            title: "Founder, Leadership Coach and Product Consultant",
            image: sophieVo,
            links: [
                { type: "linkedin", url: "https://www.linkedin.com/in/sophie-vo-riseandplay/" }
            ]
        }];
    return (
        <div className="speakers-background1">
            <div className="speakers-content">
                <div className='speakers__content__container'>
                    <h2 data-aos="fade-left" data-aos-delay="100"><span>Featured</span><br />Speakers</h2>
                    <p data-aos="fade-left" data-aos-delay="200" className="description">
                        Explore the future of digital entertainment with an inspiring speaker line-up.
                    </p>
                </div>
                <div className='speaker__content__section' data-aos="fade-right" data-aos-delay="100">
                    <p className="description">
                        Discover the visionaries and innovators transforming and leading the future of AVGC industry.
                    </p>
                    <Link to="/speakers" className='nav-link'>
                        <button className="explore-button btn btn-primary">EXPLORE MORE</button>
                    </Link>
                </div>
            </div>
            <div className="speakers-section">
                {speakers.map((speaker, index) => (
                    <div key={index} className="speaker-card" data-aos="fade-down" data-aos-delay="100">
                        <div>
                        <div className='animation__for__profile'>
                            <img src={speaker.image} alt={speaker.name} className="speaker-image" loading='lazy'/>
                        </div>
                        <h2 className="speaker-name">{speaker.name}</h2>
                        <p className="speaker-title">{speaker.title}</p>
                        </div>
                        <div className="speaker_link">
                            {speaker.links.map((link, i) => (
                                <a key={i} href={link.url} target="_blank" rel="noopener noreferrer">
                                    {link.type === "wiki" ? (
                                        // <FaWikipediaW className="speaker-icon" />
                                        <img src={wikipedia} className="speaker-icon" style={{width:32, height:32}}/>
                                    ) : link.type === "linkedin" ? (
                                        <BsLinkedin className="speaker-icon" />
                                    ) : (
                                        <span>{link.type}</span>
                                    )}
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="speaker-footer row col-12">
            <Link to="/speakers" className='nav-link'>
                View more speakers &gt;&gt;
            </Link>
            </div>
        </div>
    )
}
