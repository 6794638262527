import React, { useState } from 'react';
import indiaJoyImg from '../../Assets/images/Group.svg';
import conference from '../../Assets/images/conference.webp';
import technologyShowcase from '../../Assets/images/TechnologyShowcase.webp';
import Expo from '../../Assets/images/Expo.webp';
import Awards from '../../Assets/images/Awards.webp';
import Workshops from '../../Assets/images/Workshops.webp';
import B2B from '../../Assets/images/B2B.webp';
import Networking from '../../Assets/images/Networking.webp';
import Cosplay from '../../Assets/images/Cosplay.webp';
import ExperienceZones from '../../Assets/images/Experience zones.webp';
import Fanfest from '../../Assets/images/Fanfest.webp';
import IndustryRoundtables from '../../Assets/images/Industry roundtables.webp';
import Afterparty from '../../Assets/images/Afterparty.webp';
import CareerConnect from '../../Assets/images/Career Connect.webp';
import Screenings from '../../Assets/images/Screenings.webp';
import viewMore from '../../Assets/images/Group 273.webp';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Innovation = () => {
  const initialData = [
    { "id": 1, "title": "Conference", "image": conference },
    { "id": 2, "title": "technology showcase", "image": technologyShowcase },
    { "id": 3, "title": "Expo", "image": Expo },
    { "id": 4, "title": "Awards", "image": Awards },
    { "id": 5, "title": "Workshops", "image": Workshops },
    { "id": 6, "title": "B2B", "image": B2B },
    { "id": 7, "title": "Networking", "image": Networking },
    { "id": 8, "title": "Cosplay", "image": Cosplay },
    { "id": 9, "title": "Experience zones", "image": ExperienceZones },
    { "id": 10, "title": "Fanfest", "image": Fanfest },
    { "id": 11, "title": "Industry roundtables", "image": IndustryRoundtables },
    { "id": 12, "title": "Afterparty", "image": Afterparty },
    { "id": 13, "title": "Career Connect", "image": CareerConnect },
    { "id": 14, "title": "Screenings", "image": Screenings }
  ];

  
  const [itemsToShow, setItemsToShow] = useState(initialData);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
  };
 
  const isMobile = window.innerWidth <= 768; 
  const isTablet = window.innerWidth <= 1024;
  // const isDesktop = window.innerWidth <= 1;

  const desktopSettings = {
    slidesToShow: 5,  // Show 4 cards on a desktop screen
  };

  const tabletSettings = {
    slidesToShow: 2,  // Show 2 cards on a tablet screen
  };

  const mobileSettings = {
    slidesToShow: 1,  // Show 1 card on a mobile screen
  };

  const responsiveSettings = isMobile
    ? mobileSettings
    : isTablet
    ? tabletSettings
    : desktopSettings;


  return (
    <div className='indiaJoy__innovation__container'>
      <div className='indiaJoy__innovation__section'>
        <div className='india__innovation__itemSection' data-aos="zoom-in" data-aos-delay="100">
          <img src={indiaJoyImg} alt='indiaJoyImg' loading='lazy'/>
        </div>
        <div className='indiaJoy__innovation__title' data-aos="fade-left" data-aos-delay="100">
          <h2>Uniting <br/> innovation<br/> empowering<br/> tomorrow</h2>
          <p>IndiaJoy - India’s greatest digital entertainment festival brings to you all things entertainment - from Cosplay, Afterparty, Experience zones, and fan meets to conference / technology showcase of  Gaming, Animation, Film, and VFX, the platform is all about empowering the entire ecosystem by facilitating  B2B networking, key policy discussions, technology showcase to meeting the innovators, from upskilling to career connect - explore and experience the best that the Animation, VFX, Gaming, E-sports, Film, and E-sports world has to offer.</p>
        </div>
      </div>
      <div>
        <div className='indiaJoy__innovation__innerSection'>
          <div className="indiaJoy__innovation__innerwraper">
          <Slider {...{ ...settings, ...responsiveSettings, }}  className='indiaJoy__slick__slider'>
            {itemsToShow.map((item) => (
              <div key={item.id} className="indiaJoy__innovation__innerItems" data-aos="fade-down" data-aos-delay="100">
                <img src={item.image} alt={item.title} loading='lazy'/>
                <h2>{item.title}</h2>
              </div>
            ))}
            </Slider>
          </div>
            {/* {showAll ? <div className='indiaJoy__innerstyle'></div> : <div className="indiaJoy__viewMore" onClick={toggleShowAll}> <img src={viewMore} alt='viewMore' /></div>} */}
        </div>
      </div>
    </div>
  )
}
