import React from 'react';
import designHeader from '../../Assets/images/designHeader.webp';
import buyTickets from '../../Assets/images/buyTickets.webp';
import daily from '../../Assets/images/indiaJoy-daily.webp';
import magazine from '../../Assets/images/indiaJoy-magazine.webp';
import spotlight from '../../Assets/images/indiaJoy-spotlight.webp';
import location from '../../Assets/images/location.webp';
import novotel from '../../Assets/images/novotel.webp';
import { Link } from 'react-router-dom';

export const PurchaseTicket = () => {
    return (
        <>
        <div className='background-container purchaseTicketFrame' >
            <img className='designHeader col-12' src={designHeader} alt="Design Header" loading='lazy'/>
            <div className='purchaseTicketCardBg'>
                <div className='purchaseTicketCard'>
                    <div className='content'>
                        <h1 className='agenda-title' data-aos="fade-down" data-aos-delay="100">BE PART OF INDIAJOY 2024</h1>
                        <p className='description indiaJoy__meet__container' data-aos="fade-down" data-aos-delay="200">
                            Meet at Asia's biggest media and entertainment congregation - where the world gathers to network, trade, collaborate, share the best-in-class ideas, and champion the next generation of innovation in the digital entertainment arena.
                        </p>
                        <button className="explore-button" data-aos="fade-down" data-aos-delay="300">
                            {/* <Link to="/register" className='nav-link'>BUY TICKETS</Link> */}
                            <a href="https://events.tecogis.com/indiajoy2024/select-pass" className='nav-link' target="_blank">BUY TICKETS</a>
                        </button>
                    </div>
                    <div className='buyTickets'>
                        <img src={buyTickets} alt="Buy Tickets" className="img-fluid" loading='lazy'/>
                    </div>
                </div>
            </div>
            {/* Venue */}
            <div className="indiaJoy__venu__container">
                <div className="agenda-title" data-aos="fade-right" data-aos-delay="100">
                    VENUE
                </div>
                <div class='venue' data-aos="fade-up" data-aos-delay="100">
                    <div class='venueCard'>
                        <div class='venue-inner-card'>
                            <div>
                                <img class='locationLogo' src={location} alt='Location Logo' loading='lazy'/>
                            </div>
                            <div className='indiaJoy__novotelSection'>
                                <p>
                                    HICC NOVOTEL,<br />
                                    HYDERABAD
                                </p>
                                <a href='https://www.google.com/maps/search/?api=1&query=HICC+Novotel+Hyderabad' target='_blank' rel="noopener noreferrer">
                                    <button class='viewMap-button'>View on Map</button>
                                </a>
                            </div>
                        </div>
                        <div class='venue-inner-card-location'>
                            <a href='https://www.google.com/maps/search/?api=1&query=HICC+Novotel+Hyderabad' target='_blank' rel="noopener noreferrer">
                                <img class='locationImg' src={novotel} alt='Novotel' loading='lazy'/>
                            </a>
                        </div>
                    </div>
                    <div class='venueCard'>
                        <div class='venue-inner-card'>
                            <div>
                                <img class='locationLogo' src={location} alt='Location Logo' loading='lazy'/>
                            </div>
                            <div className='indiaJoy__novotelSection'>
                                <p>hITEX,<br/>
                                hydrabad
                                </p>
                                <a href='https://www.google.com/maps/search/?api=1&query=HICC+Novotel+Hyderabad' target='_blank' rel="noopener noreferrer">
                                    <button class='viewMap-button'>View on Map</button>
                                </a>
                            </div>
                        </div>
                        <div class='venue-inner-card-location'>
                            <a href='https://www.google.com/maps/search/?api=1&query=HICC+Novotel+Hyderabad' target='_blank' rel="noopener noreferrer">
                                <img class='locationImg' src={novotel} alt='Novotel' loading='lazy'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='indiaJoy__bgforcards'>
        <div className='cardBg'>
                <div className='coming-soon-card' data-aos="flip-right" data-aos-delay="100">
                    <div className='coming-soon-content'>
                        <img className='coming-soon-image' src={daily}  alt="btn" loading='lazy'/>
                        <p className='description__card'>
                            Get your daily dose of entertainment news in animation, VFX, gaming, esports, film, and comics. Login and relax as we deliver the latest updates from around the world straight to your inbox.
                        </p>
                        <button className="coming-soon-button"><Link to='https://indiajoydaily.com/'>View More</Link></button>
                    </div>
                </div>
                {/* <div className='coming-soon-card'>
                    <div className='coming-soon-content'>
                        <img className='coming-soon-image' src={magazine} alt="btn" />
                        <p className='description__card'>
                            Discover trending and groundbreaking events in media and entertainment with our monthly feature. Get industry round-ups and insights from innovators, researchers, students, and trailblazers in animation, VFX, gaming, esports, comics, and film
                        </p>
                        <button className="coming-soon-button"><Link to='/commingSoon'>COMING SOON</Link></button>
                    </div>
                </div> */}
                <div className='coming-soon-card' data-aos="flip-right" data-aos-delay="100">
                    <div className='coming-soon-content'>
                        <img className='coming-soon-image' src={spotlight} alt="btn" loading='lazy'/>
                        <p className='description__card'>
                            Shining a light on the heroes behind the screens transforming entertainment, one project at a time. Hear stories from legends who built our fantasy worlds and tech giants shaping the future of media and entertainment in this must-listen podcast.
                        </p>
                        <button className="coming-soon-button"><Link to='https://www.youtube.com/@indiajoy7846'>View More</Link></button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
