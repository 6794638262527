import React,{useState, useEffect} from 'react';
import Menu from '../Home/menu.jsx';
import { BuyTicket } from '../Contact/buyTicket';
import { Footer } from '../Home/footer';
import { IndustrySupport } from '../Home/industrySupport';
import AboutBanner from '../../Assets/images/aboutBanner.svg';
import JayeshRanjaniasImg from '../../Assets/images/Speakers/Jayesh Ranjan ias.svg';
import RajivChilkaImg from '../../Assets/images/Speakers/Rajiv Chilka.svg';
import MadhavReddyMikeImg from '../../Assets/images/Speakers/Madhav Reddy (Mike).svg';
import SridharMuppidiImg from '../../Assets/images/Speakers/Sridhar Muppidi.svg';
// import ArjunSarodeImg from '../../Assets/images/Speakers/Arjun Sarode.svg';
import KhajaValiImg from '../../Assets/images/Speakers/Khaja Vali.svg';
import BirenGhoseImg from '../../Assets/images/Speakers/Biren Ghose.svg';
import ShrikantSinhaImg from '../../Assets/images/Speakers/Shrikant Sinha.svg';
import AshishKulkarniImg from '../../Assets/images/Speakers/Ashish Kulkarni.svg';
import MohithSoniImg from '../../Assets/images/Speakers/Mohith Soni.svg'
import JayeshRanjanIASImg from '../../Assets/images/Jayesh Ranjan IAS.svg';
import SridharBabuImg from '../../Assets/images/Sridhar Babu.svg.svg';
import LinkdianImg from '../../Assets/images/Speakers/Vector.webp';
import SaraswathiVaniImg from '../../Assets/images/Speakers/Saraswathi Vani.webp';
import { Link } from 'react-router-dom';

const calculateTimeRemaining = (endDate) => {
  const now = new Date();
  const timeRemaining = endDate - now;
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

export const About = () => {

  const endDate = new Date('2024-11-13T00:00:00');

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(endDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(endDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const sData = [
    {
      id: 1,
      imgSrc: JayeshRanjaniasImg,
      title: 'Jayesh Ranjan ias',
      text: 'Special Chief Secretary, Department of ITE&C, Government of Telangana',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/jayesh-ranjan-37415963/?originalSubdomain=in"
    },
    {
      id: 2,
      imgSrc: RajivChilkaImg,
      title: 'Rajiv Chilka',
      text: 'CEO, Greengold Animation',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/rajiv-chilaka-5ba31426/"
    },
    {
      id: 3,
      imgSrc: MadhavReddyMikeImg,
      title: 'Madhav Reddy (Mike)',
      text: 'Managing Director, Rotomaker India Pvt Ltd',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/mikeyatham"
    },
    {
      id: 4,
      imgSrc: SridharMuppidiImg,
      title: 'Sridhar Muppidi',
      text: 'Co-founder- YesGnome',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/sridharmuppidi/"
    },
    // {
    //   id: 5,
    //   imgSrc: ArjunSarodeImg,
    //   title: 'Arjun Sarode',
    //   text: 'COO & Resident Mentor at IMAGE COE',
    //   status: LinkdianImg,
    //   link: "https://www.linkedin.com/in/sarode/"
    // },
    {
      id: 6,
      imgSrc: KhajaValiImg,
      title: 'Khaja Vali',
      text: 'COO - Telangana VFX, Animation and Gaming Association',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/shaikhkh/"
    },
  ]
  const cData = [
    {
      id: 1,
      imgSrc: BirenGhoseImg,
      title: 'Biren Ghose',
      text: 'Managing Director - Asia Pacific & Global Excom Member - Technicolor Group',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/birenghose/"
    },
    {
      id: 2,
      imgSrc: ShrikantSinhaImg,
      title: 'Shrikant Sinha',
      text: 'CEO, TASK',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/shrikant-sinha-8a74903/"
    },
    {
      id: 3,
      imgSrc: AshishKulkarniImg,
      title: 'Ashish Kulkarni',
      text: 'Chairman of FICCI for Animation, Visual Effects, Gaming and Comics Forum',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/ashish-kulkarni-6278138/"
    },
    {
      id: 4,
      imgSrc: MohithSoniImg,
      title: 'Mohith Soni',
      text: 'CEO - MESC',
      status: LinkdianImg,
      link: "https://www.linkedin.com/in/ashish-kulkarni-6278138/"
    },
    {
      id: 28,
      imgSrc: SaraswathiVaniImg,
      title: 'Saraswathi Vani',
      text: 'CEO, Creative Director - Dancing Atoms',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/vanibalgam/"
    }
  ]

  const sampleData = [
    {
      id: 1,
      imgSrc: JayeshRanjanIASImg,
      title: "D. Sridhar Babu",
      description: "Minister for Information Technology, Electronics, Communications, Industries and Commerce Government of Telangana",
      content: "The Indian VFX, Animation & Gaming Industry is at a very interesting and crucial juncture. With endless possibilities, the future will be demanding and very fruitful. The Govt of Telangana supports the local talent and soft power through IndiaJoy."
    },
    {
      id: 2,
      imgSrc: SridharBabuImg,
      title: "Jayesh Ranjan IAS",
      description: "Special Chief Secretary, Department of IT, Telangana",
      content: "Hyderabad has witnessed tremendous growth in the media and digital industry sector. Organizing a mega event like IndiaJoy makes a lot of strategic sense for the Government to boost the sector."
    }
  ]
  return (
    <div>
      <Menu />
      <div className='indiaJoy__about__wrapper'>
        <div className='indiaJoy__about__container'>
          <div className='indiaJoy__about__section1' data-aos="fade-down" data-aos-delay="100">
            <h2>ABOUT INDIAJOY</h2>
            <p>IndiaJoy is a leading aggregator platform hosting prestigious International events and bringing together the AVGC sector to unfold opportunities for Investors, Corporations, Studios, Content Developers, Delegates, Consumers, Hardware manufacturers and students, through series of Networking, Trade Exhibitions, Product Launches, B2B and B2C events. Amassing the largest stakeholder participation from across the globe, including 15+ events, 10,000+ International Participants, 25k Delegates, 30 Countries, and 50k Media Mentions, IndiaJoy is rising to be one of the pioneering Media and Entertainment events in South Asia.</p>
            <button><a href="https://events.tecogis.com/indiajoy2024/select-pass" className='nav-link' target="_blank">Book Now</a></button>
          </div>
          <div className='indiaJoy__about__section2' data-aos="zoom-in-up" data-aos-delay="100">
            <img src={AboutBanner} alt='AboutBanner' loading="lazy"/>
          </div>
        </div>
        <div className='indiaJoy__about__items'>
          <h2 className='title1' data-aos="fade-right" data-aos-delay="100">CORE COMMITTEE</h2>
          <div className='indiaJoy__speakers__container'>
            {sData.map(item => (
              <div key={item?.id} className='indiaJoy__speakers__section animation__for__profile1' data-aos="fade-down" data-aos-delay="100">
                <div>
                  <img src={item?.imgSrc} alt='' className='indiaJoy__animate' loading="lazy"/>
                  <h3>{item?.title}</h3>
                  <p>{item?.text}</p>
                </div>
                <div className='speaker_link'>
                  <a href={item?.link} target="_blank" rel="noopener noreferrer">
                    <img src={item?.status} alt='' className='indiaJoy__footer__icons' loading="lazy"/>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <h2 className='title2' data-aos="fade-right" data-aos-delay="100">ADVISORY COMMITTEE</h2>
          <div className='indiaJoy__speakers__container'>
            {cData.map(item => (
              <div key={item?.id} className='indiaJoy__speakers__section animation__for__profile1' data-aos="fade-down" data-aos-delay="100">
                <img src={item?.imgSrc} alt='' className='indiaJoy__animate' loading="lazy"/>
                <div>
                  <h3>{item?.title}</h3>
                  <p>{item?.text}</p>
                </div>
                <div className='speaker_link'>
                  <a href={item?.link} target="_blank" rel="noopener noreferrer">
                    <img src={item?.status} alt='' className='indiaJoy__footer__icons' loading="lazy"/>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='indiaJoy__govt__container'>
        <div className='indiaJoy__aboutusGovt__title' data-aos="fade-right" data-aos-delay="100">
          <h1>An initiative by <br /> the Government <br /> of Telangana</h1>
        </div>
        <div className='indiaJoy__govt__section'>
          <div className='indiaJoy__govt__items' data-aos="fade-down" data-aos-delay="100">
            {sampleData.map(item => (
              <div className='indiaJoy__govtItems'>
                <div className='indiaJoy__gridFlex'>
                  <div key={item?.id} className='indiaJoy__govt__grid'>
                    <img src={item?.imgSrc} alt='' className='' loading="lazy"/>
                  </div>
                  <div className='indiaJoy__grids'>
                    <h3>{item?.title}</h3>
                    <p>{item?.description}</p>
                  </div>
                </div>
                  <div className='indiaJoy__grid__contancts'>
                    <p>{item?.content}</p>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='indiaJoy__govt__container1'>
        <div className='indiaJoy__Digital__wrapper'>
          <h2 data-aos="fade-down" data-aos-delay="100">What is IndiaJoy aiming to do?</h2>
          <p data-aos="fade-down" data-aos-delay="200">We are aiming to promote Indian Digital content through international relations and provide leadership and growth to the Digital media and Entertainment industry in the country. We believe the collaboration of our event partners, Government of Telangana and IndiaJoy will make the industry more reachable and inspiring to all.</p>
          <h4 data-aos="fade-down" data-aos-delay="300">EVENT IN:</h4>
          <div className='indiaJoy__Digital__section' data-aos="fade-down" data-aos-delay="400">
            <div className='indiaJoy__Digital__grids'>
              <h3>{timeRemaining.days}</h3>
              <p>DAYS</p>
            </div>
            <div className='indiaJoy__Digital__grids'>
            <h3>{timeRemaining.hours}</h3>
              <p>HOURS</p>
            </div>
            <div className='indiaJoy__Digital__grids'>
            <h3>{timeRemaining.minutes}</h3>
              <p>MINUTES</p>
            </div>
            <div className='indiaJoy__Digital__grids'>
            <h3>{timeRemaining.seconds}</h3>
              <p>SECONDS</p>
            </div>
          </div>
        </div>
      </div>
      <IndustrySupport />
      <BuyTicket />
      <Footer />
    </div>
  )
}
