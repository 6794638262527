import React from "react";
import adobe from "../../Assets/images/industrySupporters/adobe.webp";
import aha from "../../Assets/images/industrySupporters/aha.webp";
import amd from "../../Assets/images/industrySupporters/amd.webp";
import aputure from "../../Assets/images/industrySupporters/aputure.webp";
import autodesk from "../../Assets/images/industrySupporters/autodesk.webp";
import basilicFly from "../../Assets/images/industrySupporters/basilicFly.webp";
import bot from "../../Assets/images/industrySupporters/bot.webp";
import canon from "../../Assets/images/industrySupporters/canon.webp";
import digitalDomain from "../../Assets/images/industrySupporters/digitalDomain.webp";
import dneg from "../../Assets/images/industrySupporters/dneg.webp";
import epicGames from "../../Assets/images/industrySupporters/epicGames.webp";
import foundry from "../../Assets/images/industrySupporters/foundry.webp";
import greenGold from "../../Assets/images/industrySupporters/greenGold.webp";
import hornbill from "../../Assets/images/industrySupporters/hornbill.webp";
import krafton from "../../Assets/images/industrySupporters/krafton.webp";
import nazara from "../../Assets/images/industrySupporters/nazara.webp";
import rotomaker from "../../Assets/images/industrySupporters/rotomaker.webp";
import sony from "../../Assets/images/industrySupporters/sony.webp";
import supermicro from "../../Assets/images/industrySupporters/supermicro.webp";
import technicolor from "../../Assets/images/industrySupporters/technicolor.webp";
import toonz from "../../Assets/images/industrySupporters/toonz.webp";
import unity from "../../Assets/images/industrySupporters/unity.webp";
import unrealEngine from "../../Assets/images/industrySupporters/unrealEngine.webp";
import victoriaStateGovernement from "../../Assets/images/industrySupporters/victoriaStateGovernement.webp";
import wacom from "../../Assets/images/industrySupporters/wacom.webp";
import winzo from "../../Assets/images/industrySupporters/winzo.webp";
import xpPen from "../../Assets/images/industrySupporters/xpPen.webp";
import zeiss from "../../Assets/images/industrySupporters/zeiss.webp";
import ves from "../../Assets/images/ecosystemPartners/ves.webp";
import abai from "../../Assets/images/ecosystemPartners/abai.webp";
import animationXpress from "../../Assets/images/ecosystemPartners/animationXpress.webp";
import asfiaIndia from "../../Assets/images/ecosystemPartners/asifaIndia.webp";
import cii from "../../Assets/images/ecosystemPartners/cii.webp";
import ficci from "../../Assets/images/ecosystemPartners/ficci.webp";
import fmx from "../../Assets/images/ecosystemPartners/fmx.webp";
import hysea from "../../Assets/images/ecosystemPartners/hysea.webp";
import iacc from "../../Assets/images/ecosystemPartners/iacc.webp";
import investIndia from "../../Assets/images/ecosystemPartners/investIndia.webp";
import meai from "../../Assets/images/ecosystemPartners/meai.webp";
import mesc from "../../Assets/images/ecosystemPartners/mesc.webp";
import sepc from "../../Assets/images/ecosystemPartners/sepc.webp";
import tca from "../../Assets/images/ecosystemPartners/tca.webp";
import vfx from "../../Assets/images/ecosystemPartners/vfx.webp";
import pickle from "../../Assets/images/industrySupporters/pickle.svg";
import viewconference from "../../Assets/images/ecosystemPartners/view-conference.jpg";
import SptiPreview from "../../Assets/images/STPI_Preview.png";
import SptiLogo from "../../Assets/images/STPI-logo.png";

export const IndustrySupport = () => {
  const industrySupporters = [
    {
      image: unrealEngine,
      link: "https://www.unrealengine.com/en-US",
    },
    {
      image: sony,
      link: "https://www.sony.com/en/",
    },
    {
      image: nazara,
      link: "https://www.nazara.com/",
    },
    {
      image: autodesk,
      link: "https://www.autodesk.com/",
    },
    {
      image: amd,
      link: "https://www.amd.com/en.html",
    },
    {
      image: adobe,
      link: "https://www.adobe.com/in/",
    },
    {
      image: aha,
      link: "https://www.aha.video/",
    },
    {
      image: digitalDomain,
      link: "https://digitaldomain.com/",
    },
    {
      image: foundry,
      link: "https://www.foundry.com/",
    },
    {
      image: rotomaker,
      link: "https://rotomaker.com/",
    },
    {
      image: dneg,
      link: "https://www.dneg.com/",
    },
    {
      image: epicGames,
      link: "https://store.epicgames.com/en-US/",
    },
    {
      image: zeiss,
      link: "https://www.zeiss.co.in/corporate/home.html",
    },
    {
      image: toonz,
      link: "https://toonz.co/",
    },
    {
      image: basilicFly,
      link: "https://www.basilicflystudio.com/",
    },
    {
      image: wacom,
      link: "https://www.wacom.com/en-in",
    },
    {
      image: xpPen,
      link: "https://www.xp-pen.com/",
    },
    {
      image: greenGold,
      link: "https://www.greengold.tv/",
    },
    {
      image: aputure,
      link: "https://www.aputure.com/",
    },
    {
      image: unity,
      link: "https://unity.com/",
    },
    {
      image: victoriaStateGovernement,
      link: "https://www.vic.gov.au/",
    },
    {
      image: winzo,
      link: "https://www.winzogames.com/",
    },
    {
      image: technicolor,
      link: "https://www.technicolor.com/",
    },
    {
      image: canon,
      link: "https://in.canon/",
    },
    {
      image: krafton,
      link: "https://krafton.com/en/",
    },
    {
      image: supermicro,
      link: "https://www.supermicro.com/en/home",
    },
    {
      image: hornbill,
      link: "https://hornbillstudios.com/",
    },
    {
      image: bot,
      link: "https://botvfx.com/",
    },
    {
      image: pickle,
      link: "https://pickle.co.in/",
    },
  ];
  const ecosystemPartners = [
    {
      image: ves,
      link: "https://www.vesglobal.org/group/india/",
    },
    {
      image: abai,
      link: "https://abai.avgc.in/",
    },
    {
      image: mesc,
      link: "https://www.mescindia.org/",
    },
    {
      image: asfiaIndia,
      link: "https://www.asifaindia.com/",
    },
    {
      image: meai,
      link: "https://meai.in/",
    },
    {
      image: vfx,
      link: "https://www.vfxexpress.com/",
    },
    {
      image: animationXpress,
      link: "https://www.animationxpress.com/",
    },
    {
      image: iacc,
      link: "https://www.iaccindia.com/",
    },
    {
      image: hysea,
      link: "https://hysea.in/",
    },
    {
      image: investIndia,
      link: "https://www.investindia.gov.in/",
    },
    {
      image: ficci,
      link: "https://www.ficci.in/api/home",
    },
    {
      image: sepc,
      link: "https://www.servicesepc.org/",
    },
    {
      image: cii,
      link: "https://www.cii.in/",
    },
    {
      image: fmx,
      link: "https://fmx.de/en/home",
    },
    {
      image: tca,
      link: "https://thetca.in/",
    },
    {
      image: viewconference,
      link: "",
    },
  ];
  return (
    <div>
      <div
        className="agenda-title indiaJoy__topTitle"
        data-aos="fade-right"
        data-aos-delay="100"
      >
        INDUSTRY SUPPORTERS
      </div>
      <div className="logos">
        {industrySupporters.map((supporter, index) => (
          <div
            key={index}
            className="imgSupporter"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="d-flex justify-content-center">
              <a
                href={supporter.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={supporter.image} alt="" loading="lazy" />
              </a>
            </div>
          </div>
        ))}
      </div>

      <div
        className="agenda-title indiaJoy__topTitle"
        data-aos="fade-right"
        data-aos-delay="100"
      >
     INCUBATION PARTNERS
      </div>
      <div className="logos">
        <div          
          className="imgSupporter"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <div className="d-flex justify-content-center">
            {/* <a href={partner.link} target="_blank" rel="noopener noreferrer"> */}
              <img src={SptiPreview} alt="" loading="lazy" />
            {/* </a> */}
          </div>
        </div>

        <div          
          className="imgSupporter"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <div className="d-flex justify-content-center">
            {/* <a href={partner.link} target="_blank" rel="noopener noreferrer"> */}
              <img src={SptiLogo} alt="" loading="lazy" style={{width: 300}} />
            {/* </a> */}
          </div>
        </div>
      </div>

      <div
        className="agenda-title indiaJoy__topTitle"
        data-aos="fade-right"
        data-aos-delay="100"
      >
        ECOSYSTEM PARTNERS
      </div>
      <div className="logos">
        {ecosystemPartners.map((partner, index) => (
          <div
            key={index}
            className="imgSupporter"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="d-flex justify-content-center">
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <img src={partner.image} alt="" loading="lazy" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
