import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import vedio from '../../Assets/images/background_video.mp4';
// import ReactPlayer from 'react-player/lazy';

const Popup = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
        <div className='indaiJoy__modal__lg'>
        {/* <ReactPlayer
          className='indiaJoy__bannerInfo'
          url={vedio}
          width='100%'
          height='100%'
          playing='false'
        /> */}
     <iframe
          className='indiaJoy__bannerInfo__vedio'
          title="INDIAJOY - Event 4 - Desi Toons 2021"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          autoFocus
          src={vedio}
        />
        </div>
    </Modal>
  );
};

export default Popup;
