import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import viewMore from "./Assets/images/Group 273.webp";
// pages
import { Index } from "./components/Home/index";
import { About } from "./components/About/about";
import { Events } from "./components/Events/events";
import { Agenda } from "./components/Agenda/agenda";
import { Speakers } from "./components/Speakers/speakers";
import { PastEditions } from "./components/PastEditions/pastEditions";
import { Contact } from "./components/Contact/contact";
import { CommingSoon } from "./components/Commingsoon/commingSoon";
import { Register } from "./components/Register/Register";
import { GlobalPage } from "./components/Home/global";
import ScrollToTopPages from "./ScrollToTopPages";

function App() {
  // top scroll
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisibility);

  return (
    <div>
      <BrowserRouter>
        <ScrollToTopPages />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/about" component={About} />
          <Route path="/events" component={Events} />
          <Route path="/pastEditions" component={PastEditions} />
          <Route path="/speakers" component={Speakers} />
          <Route path="/agenda" component={Agenda} />
          <Route path="/contact" component={Contact} />
          <Route path="/commingSoon" component={CommingSoon} />
          <Route path="/register" component={Register} />
          <Route path="/gyp" component={GlobalPage} />
        </Switch>
      </BrowserRouter>

      <div className="scroll-to-top">
        {isVisible && (
          <button onClick={scrollToTop}>
            <img src={viewMore} alt="viewMore" loading="lazy"/>
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
