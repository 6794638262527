import React, { useRef } from "react";
import platFormImg from "../../Assets/images/1.svg";
import daysImg from "../../Assets/images/5.svg";
import eventsImg from "../../Assets/images/7.svg";
import gamingImg from "../../Assets/images/Frame 22.svg";
import eSportImg from "../../Assets/images/Frame 23.svg";
import comicsImg from "../../Assets/images/Frame 24.svg";
import vfxImg from "../../Assets/images/Frame 25.svg";
import animationImg from "../../Assets/images/Frame 26.svg";
import animation1Img from "../../Assets/images/Frame 28.svg";
import filmImg from "../../Assets/images/Frame 27.svg";
import Globalopz from "../../Assets/images/Frame 33.svg";
import amdgameon from "../../Assets/images/amdgameon.svg";
import amd from "../../Assets/images/industrySupporters/amd.webp";
import comicstreet from "../../Assets/images/comicstreet.svg";
import amdgameoncardlogo from '../../Assets/images/amdcardlogo.png'
import amdpoweredby from '../../Assets/images/amd-powerded-by-img.png';

export const MainSection = () => {
  return (
    <div>
      <div className="indiaJoy__mainSection__wrapper">
        <div className="indiaJoy__mainSection__container">
          <div
            className="agenda-title poweredby"
            data-aos="fade-right"
            data-aos-delay="100"
            style={{
              marginTop: "-62px",
              fontSize: "15px",
              paddingBottom: "0px",
            }}
          >
            POWERED BY
          </div>
          <div className="logos poweredbyamd" style={{ marginBottom: 50 }}>
            <div
              className="imgSupporter"
              data-aos="fade-down"
              data-aos-delay="100"
              style={{ marginBottom: 50, paddingTop: "0px" }}
            >
              <div className="d-flex justify-content-center">
                <a
                  href="https://www.amd.com/en.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={amdpoweredby} alt="" loading="lazy" style={{width: 'unset'}} />
                </a>
              </div>
            </div>
          </div>

          <div className="indiaJoy__mainSection__section">
            <div
              className="indiaJoy__mainSection__item customImg"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={platFormImg} alt="platFormImg" loading="lazy" />
              <p>platform</p>
            </div>
            <div
              className="indiaJoy__mainSection__item"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img src={daysImg} alt="daysImg" loading="lazy" />
              <p>Days</p>
            </div>
            <div
              className="indiaJoy__mainSection__item"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img src={eventsImg} alt="eventsImg" loading="lazy" />
              <p>Events</p>
            </div>
          </div>
          <button className="indiaJoy__banner__btn">
            <a
              href="https://events.tecogis.com/indiajoy2024/select-pass"
              className="nav-link"
              target="_blank"
            >
              Book Now
            </a>
          </button>
        </div>
        <div className="indiaJoy__powering__wrapper">
          <div className="indiaJoy__powering__container">
            <h2>
              Powering the Joy of <br />
              Entertainment
            </h2>
            <p>
              IndiaJoy is a 5-days mega premium digital entertainment venture
              bringing together the best Gaming, VFX, Animation, Film, E-sports,
              and Comics sector events under one roof.
            </p>
          </div>
        </div>
        <div className="indiaJoy__gamingSection__wraper" id="mainSection">
          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="indiaJoy__gamingSection__items">
              <h2>Gaming</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={gamingImg} alt="gamingImg" loading="lazy" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    IGDC, India's oldest and largest game developer conference,
                    supports the growth of the Indian gaming ecosystem by
                    providing insights, upskilling, and networking opportunities
                    for developers, publishers, and investors.
                  </p>
                  <button>
                    <a
                      href="https://indiagdc.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Conference</button>
                <button>Expo</button>
                <button>Awards</button>
                <button>Game Connect</button>
                <button>BYOG (Build Your Own Game)</button>
                <button>Workshops</button>
                <button>Career Connect</button>
                <button>Industry Roundtables</button>
                <button>Women in Games</button>
                <button>Afterparty</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 13-15 </p>
              </div>
            </div>

            <div className="indiaJoy__gamingSection__items">
              <h2>VFX</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={vfxImg} alt="vfxImg" loading="lazy" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    VFX Summit, India's pioneering VFX technology conference,
                    connects industry professionals and artists to showcase VFX
                    and CG advancements. It fosters networking, collaboration,
                    and growth in the Indian VFX industry through various
                    initiatives.
                  </p>
                  <button>
                    <a
                      href="https://vfxsummit.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Conference</button>
                <button>Expo</button>
                <button>Workshops</button>
                <button>Industry Roundtables</button>
                <button>VFX Collective</button>
                <button>Competitions</button>
                <button>Afterparty</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
            {/* <div className='indiaJoy__gamingSection__items'>
              <h2>E-SPORTS</h2>
              <div className='indiaJoy__gamingSection'>
                <div className='indiaJoy__leftItem'>
                  <img src={eSportImg} alt='gamingImg' />
                </div>
                <div className='indiaJoy__text'>
                  <p>DreamHack is an international gaming festival known for its 3-day celebration of tech, pop culture, gaming, and cosplay. DreamHack Hyderabad offers 72 hours of action-packed esports, gaming, and cosplay events.</p>
                  <button><a href='https://dreamhack.com/india/' target="_blank" rel="noopener noreferrer" >Learn more</a></button>
                </div>
              </div>
              <div className='indiaJoy__suggestions'>
                <button>E-Sports Carnival</button>
                <button>Expo</button>
                <button>Cosplay</button>
                <button>Meet and Greet</button>
                <button>BYOD (Bring Your Own Device)</button>
                <button>Streamer Zone</button>
              </div>
              <div className='indiaJoy__date'>
                <p>NOV 15-17</p>
              </div>
            </div> */}
          </div>
          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <div className='indiaJoy__gamingSection__items'>
              <h2>COMICS</h2>
              <div className='indiaJoy__gamingSection'>
                <div className='indiaJoy__leftItem'>
                  <img src={comicsImg} alt='comicsImg' />
                </div>
                <div className='indiaJoy__text'>
                  <p>Comiccon is the ultimate fan convention focused on comic books and culture, where fans meet creators, experts, and the community. Comiccon Hyderabad features celebrity guests, merchandise, unique experiences, and top cosplayers.</p>
                  <button><a href='https://www.comicconindia.com/all-cities-main-page/hyderabad-comic-con' target="_blank" rel="noopener noreferrer" >Learn more</a></button>
                </div>
              </div>
              <div className='indiaJoy__suggestions'>
                <button>Cosplay</button>
                <button>Comics</button>
                <button>Experience Zones</button>
                <button>Fanfest</button>
                <button>Exclusive Merchandise</button>
              </div>
              <div className='indiaJoy__date'>
                <p>NOV 15-17</p>
              </div>
            </div> */}
            {/* <div className='indiaJoy__gamingSection__items'>
              <h2>VFX</h2>
              <div className='indiaJoy__gamingSection'>
                <div className='indiaJoy__leftItem'>
                  <img src={vfxImg} alt='vfxImg' />
                </div>
                <div className='indiaJoy__text'>
                  <p>VFX Summit, India's pioneering VFX technology conference, connects industry professionals and artists to showcase VFX and CG advancements. It fosters networking, collaboration, and growth in the Indian VFX industry through various initiatives.</p>
                  <button><a href='https://vfxsummit.in/' target="_blank" rel="noopener noreferrer" >Learn more</a></button>
                </div>
              </div>
              <div className='indiaJoy__suggestions'>
                <button>Conference</button>
                <button>Expo</button>
                <button>Workshops</button>
                <button>Industry Roundtables</button>
                <button>VFX Collective</button>
                <button>Competitions</button>
                <button>Afterparty</button>
              </div>
              <div className='indiaJoy__date'>
                <p>NOV 16-17</p>
              </div>
            </div> */}
          </div>
          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="indiaJoy__gamingSection__items">
              <h2>ANIMATION</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={animationImg} alt="animationImg" loading="lazy" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    Desi Toons, a one-day conclave within Indiajoy, unites
                    animation production, broadcasting, and licensing industry
                    stakeholders. It celebrates Indian animation, focusing on
                    indigenous IPs, their global potential, and the future of
                    Indian animation.
                  </p>
                  <button>
                    <a
                      href="https://desitoons.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Conference</button>
                <button>Workshops</button>
                <button>Awards</button>
                <button>Content Market</button>
                <button>Expo</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
            <div className="indiaJoy__gamingSection__items">
              <h2>fILM</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={filmImg} alt="filmImg" loading="lazy" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    Cinematica is a premier cinematography and film technology
                    conference that connects filmmakers with top technicians and
                    directors. It fosters networking and upskilling to
                    streamline the film industry ecosystem.
                  </p>
                  <button>
                    <a
                      href="https://cinematicaexpo.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Conference</button>
                <button>Expo</button>
                <button>Awards</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
          </div>
          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="indiaJoy__gamingSection__items">
              <h2>ANIMATION</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={animation1Img} alt="animation1Img" loading="lazy" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    ASIFA India Conference, one of India's oldest animation
                    collectives, empowers artists, students, and industry
                    stakeholders by showcasing the latest animation technology
                    breakthroughs and discussing the future of CG.
                  </p>
                  <button>
                    <a
                      href="https://www.asifaindia.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Conference</button>
                <button>Awards</button>
                <button>Competitions</button>
                <button>Challenges</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
            {/* <div className='indiaJoy__gamingSection__items'>
              <h2>Globalopz</h2>
              <div className='indiaJoy__gamingSection'>
                <div className='indiaJoy__leftItem'>
                  <img src={Globalopz} alt='Globalopz' loading='lazy'/>
                </div>
                <div className='indiaJoy__text'>
                  <p>Indiajoy Global Youth Program 2024 is an interactive bootcamp for 100 young global leaders, emphasizing networking and skill enhancement in the creative industries. It aims to build deep cross-border partnerships among emerging talents.</p>
                  <button><Link to="/gyp" className='nav-link'>Learn more</Link></button>
                </div>
              </div>
              <div className='indiaJoy__date'>
                <p>NOV 13-17</p>
              </div>
            </div> */}

            <div className="indiaJoy__gamingSection__items amd-gameon">
              <h2>AMD gameOn</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img
                    src={amdgameoncardlogo}
                    alt="comicsImg"
                    style={{ width: 100, height: 100 }}
                  />
                </div>
                <div className="indiaJoy__text">
                  <p>
                  AMD GameOn, is one of India's premier eSports and technology showcase, bringing in the entire gaming and technology ecosystem together. This showcases the latest advancements in cutting-edge gaming and graphics technology. Designed for gamers and creators, event boasts product demonstrations, competitiive events, and insights from industry experts..
                  </p>
                  <button>
                    <a
                      href="https://www.amdgameon.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">    
              <button>E-Sports</button>
              <button>AI PC</button>     
              <button>Virtual Reality</button>       
                <button>Technology Showcase</button>                
                <button>Cosplay</button>
                <button>Freeplay Zone</button>                
                <button>Giveaways</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
          </div>

          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="indiaJoy__gamingSection__items">
              <h2>COMICS</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={comicsImg} alt="comicsImg" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    Comiccon is the ultimate fan convention focused on comic
                    books and culture, where fans meet creators, experts, and
                    the community. Comiccon Hyderabad features celebrity guests,
                    merchandise, unique experiences, and top cosplayers.
                  </p>
                  <button>
                    <a
                      href="https://www.comicconindia.com/all-cities-main-page/hyderabad-comic-con"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>Cosplay</button>
                <button>Comics</button>
                <button>Experience Zones</button>
                <button>Fanfest</button>
                <button>Exclusive Merchandise</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 15-17</p>
              </div>
            </div>

            <div className="indiaJoy__gamingSection__items">
              <h2>E-SPORTS</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={eSportImg} alt="gamingImg" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                    DreamHack is an international gaming festival known for its
                    3-day celebration of tech, pop culture, gaming, and cosplay.
                    DreamHack Hyderabad offers 72 hours of action-packed
                    esports, gaming, and cosplay events.
                  </p>
                  <button>
                    <a
                      href="https://dreamhack.com/india/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
                <button>E-Sports Carnival</button>
                <button>Expo</button>
                <button>Cosplay</button>
                <button>Meet and Greet</button>
                <button>BYOD (Bring Your Own Device)</button>
                <button>Streamer Zone</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 15-17</p>
              </div>
            </div>
          </div>
          <div
            className="indiaJoy__gamingSection__container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="indiaJoy__gamingSection__items">
              <h2>COMICS</h2>
              <div className="indiaJoy__gamingSection">
                <div className="indiaJoy__leftItem">
                  <img src={comicstreet} alt="comicsImg" />
                </div>
                <div className="indiaJoy__text">
                  <p>
                  Comic Street is your ultimate destination for all things comics! Dive into a myriad of initiatives designed to engage and inspire the comic community.
                  </p>
                  <button>
                    <a
                      href="https://comicstreet.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </button>
                </div>
              </div>
              <div className="indiaJoy__suggestions">
              <button>Showcase</button>
                <button>Network</button>
                <button>Membership</button>
                <button>Awards</button>
                <button>Conferences</button>
                <button>Community</button>
                <button>Exhibit Hall</button>
                <button>Merchandise</button>
                <button>Cosplay & Competitions</button>
                <button>Workshops & Webinars</button>
              </div>
              <div className="indiaJoy__date">
                <p>NOV 16-17</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
