import React from 'react';
import Menu from './menu';
import { Banner } from './banner';
import { Count } from './count';
import { MainSection } from './mainSection';
import { Innovation } from './innovation';
import '../../Assets/styles/index.css';
import { Speakers } from "./speakers";
import { Agenda } from "./agenda";
import { Gallery } from "./gallery";
import { PurchaseTicket } from "./purchaseTicket";
import { Footer } from "./footer";

export const Index = () => {
    return (
        <div className="all-sections">
            <div><Menu /></div>
            <div ><Banner /></div>
            <div ><Count /></div>
            <div ><MainSection /></div>
            <div ><Innovation /></div>
            <div ><Speakers /></div>
            <div ><Agenda /></div>
            <div ><Gallery /></div>
            <div ><PurchaseTicket /></div>
            <div ><Footer /></div>
        </div>
    )
}
