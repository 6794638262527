import React from 'react';
import Menu from '../Home/menu';
import { BuyTicket } from '../Contact/buyTicket';
import { Footer } from '../Home/footer';
import NagashwiniImg from '../../Assets/images/Speakers/Nag Ashwin.webp';
import SrinivasMohanImg from '../../Assets/images/Speakers/Srinivas Mohan.webp';
import BirenGhoseImg from '../../Assets/images/Speakers/Biren Ghose.svg';
import SamitShettyImg from '../../Assets/images/Speakers/Samit Shetty.webp';
import DustinAdairImg from '../../Assets/images/Speakers/Dustin Adair.webp';
import JamieBriensImg from '../../Assets/images/Speakers/Jamie Briens.webp';
import MikaelAndersonImg from '../../Assets/images/Speakers/Mikael Anderson.webp';
import ShrutiRajkumarImg from '../../Assets/images/Speakers/Shruti Rajkumar.webp';
import AgnesKimImg from '../../Assets/images/Speakers/Agnes Kim.svg';
import SeanSohnImg from '../../Assets/images/Speakers/Sean Sohn.webp';
import DennisMickaImg from '../../Assets/images/Speakers/Dennis Micka.webp';
import SophieVoImg from '../../Assets/images/Speakers/Sophie Vo.webp';
import JoeWeinhofferImg from '../../Assets/images/Speakers/Joe Weinhoffer.webp';
// import BasireddyImg from '../../Assets/images/Speakers/Basi reddy.png';
import RyanFarajiImg from '../../Assets/images/Speakers/Ryan Faraji.webp';
import AdityaSwamyImg from '../../Assets/images/Speakers/Aditya Swamy.svg';
import MadhavReddyMikeImg from '../../Assets/images/Speakers/Madhav Reddy (Mike).svg';
import NitishMittersainImg from '../../Assets/images/Speakers/Nitish Mittersain.webp';
import PraveenKilaruImg from '../../Assets/images/Speakers/Praveen Kilaru.webp';
import JustinSwartImg from '../../Assets/images/Speakers/Justin Swart.webp';
import SenthilKumarImg from '../../Assets/images/Speakers/Senthil Kumar.webp';
import VIKMalayalaImg from '../../Assets/images/Speakers/VIK Malayala.webp';
import RobyJohnImg from '../../Assets/images/Speakers/Roby John.webp';
import RajivChilakaImg from '../../Assets/images/Speakers/Rajiv Chilaka.webp';
import KabirVermaImg from '../../Assets/images/Speakers/Kabir Verma.webp';
import BikramBediImg from '../../Assets/images/Speakers/Bikram Bedi.webp';
import AkhauriPSinhaImg from '../../Assets/images/Speakers/Akhauri P Sinha.svg';
import AshishKulkarniImg from '../../Assets/images/Speakers/Ashish Kulkarni.svg';
import ShrikantSinhaImg from '../../Assets/images/Speakers/Shrikant Sinha.svg';
import AnantRoongtaImg from '../../Assets/images/Speakers/Anant Roongta.svg';
import MohitSoniImg from '../../Assets/images/Speakers/Mohit Soni.webp';
import SachithaKalingamudaliImg from '../../Assets/images/Speakers/Sachitha Kalingamudali.webp';
import KeertiSinghImg from '../../Assets/images/Speakers/Keerti Singh.webp';
import SaraswathiVaniImg from '../../Assets/images/Speakers/Saraswathi Vani.webp';
import RajAnanthaImg from '../../Assets/images/Speakers/Raj Anantha.webp';
import CVRaoImg from '../../Assets/images/Speakers/CV Rao.webp';
import AsifBhushanImg from '../../Assets/images/Speakers/Asif Bhushan.webp';
import AnoopAKImg from '../../Assets/images/Speakers/Anoop A K.webp';
import RajanNavaniImg from '../../Assets/images/Speakers/Rajan Navani.webp';
import ShrutiVermaImg from '../../Assets/images/Speakers/Shruti Verma.webp';
import BSSrinivasImg from '../../Assets/images/Speakers/B.S. Srinivas.webp';
import RasoolElloreImg from '../../Assets/images/Speakers/Rasool Ellore.webp';
import PrashanthBuyyalaImg from '../../Assets/images/Speakers/Prashanth Buyyala.webp';
import CharuviPAgrawalImg from '../../Assets/images/Speakers/Charuvi P. Agrawal.webp';


import wImg from '../../Assets/images/Speakers/Layer1.webp';
import LinkdianImg from '../../Assets/images/Speakers/Vector.webp';

export const Speakers = () => {
  const sData = [
    // 1
    {
      id: 1,
      imgSrc: NagashwiniImg,
      title: 'Nag Ashwin',
      text: 'Indian film director and screenwriter',
      status: wImg,
      link:"https://en.wikipedia.org/wiki/Nag_Ashwin"
    },
    {
      id: 2,
      imgSrc: SrinivasMohanImg,
      title: 'Srinivas Mohan',
      text: 'Visual Effects Supervisor',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/srinivas-mohan-a205a225/"
    },
    {
      id: 3,
      imgSrc: BirenGhoseImg,
      title: 'Biren Ghose',
      text: 'Managing Director - Asia Pacific & Global Excom Member - Technicolor Group',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/birenghose/"
    },
    {
      id: 4,
      imgSrc: SamitShettyImg,
      title: 'Samit Shetty',
      text: 'Sr. Technical Solutions Specialist - Media & Entertainment at Autodesk',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/samitshetty11/"
    },
    // 2
    {
      id: 5,
      imgSrc: DustinAdairImg,
      title: 'Dustin Adair',
      text: 'CEO / Founder, Scary Robot Productions Inc.',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/dustinadair/"
    },
    {
      id: 6,
      imgSrc: JamieBriensImg,
      title: 'Jamie Briens',
      text: 'Director of Digital Transformation at Basilic Fly Studio',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/jamiebriens/"
    },
    {
      id: 7,
      imgSrc: MikaelAndersonImg,
      title: 'Mikael Anderson',
      text: 'Game Director at Paradox Interactive',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/malandersson/"
    },
    {
      id: 8,
      imgSrc: ShrutiRajkumarImg,
      title: 'Shruti Rajkumar',
      text: 'Vice President - Invest India | Head - Film Facilitation Office',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/shruti-rajkumar-a375a031/"
    },
    // 3
    {
      id: 9,
      imgSrc: AgnesKimImg,
      title: 'Agnes Kim',
      text: 'Director at Xbox',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/agnes-kim/"
    },
    {
      id: 10,
      imgSrc: SeanSohnImg,
      title: 'Sean Sohn',
      text: 'CEO at Krafton India, Head of India Div. at Krafton Inc.',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/sean-hyunil-sohn-1670216/"
    },
    {
      id: 11,
      imgSrc: DennisMickaImg,
      title: 'Dennis Micka',
      text: 'Senior Game Designer at Guerrilla Games',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/dennis-micka-631b1444/"
    },
    {
      id: 12,
      imgSrc: SophieVoImg,
      title: 'Sophie Vo',
      text: 'Founder, Leadership Coach and Product Consultant',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/sophie-vo-riseandplay/"
    },
    // 4
    {
      id: 13,
      imgSrc: JoeWeinhofferImg,
      title: 'Joe Weinhoffer',
      text: 'Game Director at Firaxis Games',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/jweinhoffer/"
    },
    // {
    //   id: 14,
    //   imgSrc: BasireddyImg,
    //   title: 'Basi reddy',
    //   text: 'Chairman and Managing Director - Digiquest',
    //   status: LinkdianImg,
    //   link:"https://www.linkedin.com/in/basireddy/"
    // },
    {
      id: 15,
      imgSrc: RyanFarajiImg,
      title: 'Ryan Faraji',
      text: 'XDI Dev Director EA Sports and Lifestyle',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/rfaraji/"
    },
    {
      id: 16,
      imgSrc: AdityaSwamyImg,
      title: 'Aditya Swamy',
      text: 'Director at Google',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/aditya-swamy-bb355921/"
    },
    // 5
    {
      id: 17,
      imgSrc: MadhavReddyMikeImg,
      title: 'Madhav Reddy (Mike)',
      text: 'Managing Director, Rotomaker India Pvt Ltd',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/mikeyatham/"
    },
    {
      id: 18,
      imgSrc: NitishMittersainImg,
      title: 'Nitish Mittersain',
      text: 'CEO, Nazara',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/mittersain/"
    },
    {
      id: 19,
      imgSrc: PraveenKilaruImg,
      title: 'Praveen Kilaru',
      text: 'Sr Producer, Industrial Light & Magic',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/praveen-kilaru-82a10123/"
    },
    {
      id: 20,
      imgSrc: JustinSwartImg,
      title: 'Justin Swart',
      text: 'VC @ BITKRAFT Venture',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/justinswart1/"
    },
    // 6
    {
      id: 21,
      imgSrc: SenthilKumarImg,
      title: 'Senthil Kumar',
      text: 'Cinematographer',
      status: LinkdianImg,
    },
    {
      id: 22,
      imgSrc: VIKMalayalaImg,
      title: 'VIK Malayala',
      text: 'MD & President - Supermicro',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/malyala/"
    },
    {
      id: 23,
      imgSrc: RobyJohnImg,
      title: 'Roby John',
      text: 'CEO and Founder of SuperGaming',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/robytj/"
    },
    {
      id: 24,
      imgSrc: RajivChilakaImg,
      title: 'Rajiv Chilaka',
      text: 'Founder and Managing Director at Green Gold Animation Pvt. Ltd',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/rajiv-chilaka-5ba31426/"
    },
    // 7
    {
      id: 25,
      imgSrc: KabirVermaImg,
      title: 'Kabir Verma',
      text: 'CG Supervisor at ILM',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/kabir-verma-8208803b/"
    },
    {
      id: 26,
      imgSrc: BikramBediImg,
      title: 'Bikram Bedi',
      text: 'Vice President & Country Managing Director - Google Cloud, India Region',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/bikramsinghbedi/"
    },
    {
      id: 27,
      imgSrc: AkhauriPSinhaImg,
      title: 'Akhauri P Sinha',
      text: 'Managing Director (India)Managing Director (India), Framestore',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/akhauri-p-sinha-592775a5/"
    },
    {
      id: 28,
      imgSrc: AshishKulkarniImg,
      title: 'Ashish Kulkarni',
      text: 'Chairman of FICCI for Animation, Visual Effects, Gaming and Comics Forum',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/ashish-kulkarni-6278138/"
    },
    // 8
    {
      id: 28,
      imgSrc: ShrikantSinhaImg,
      title: 'Shrikant Sinha',
      text: 'CEO, TASK',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/shrikant-sinha-8a74903/"
    },
    {
      id: 28,
      imgSrc: AsifBhushanImg,
      title: 'Asif Bhushan',
      text: 'Senior Creative Head - PhantomFX',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/asif-bhushan-262a97a/"
    },
    {
      id: 28,
      imgSrc: AnantRoongtaImg,
      title: 'Anant Roongta',
      text: 'Managing Director - Famous studios',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/anant-roongta-554b608/"
    },
    {
      id: 28,
      imgSrc: MohitSoniImg,
      title: 'Mohit Soni',
      text: 'CEO - MESC',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/sonimohit1/"
    },
    // 9
    {
      id: 28,
      imgSrc: SachithaKalingamudaliImg,
      title: 'Sachitha Kalingamudali',
      text: 'Senior Manager - Digital Media at Derana',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/sachithask/"
    },
    {
      id: 28,
      imgSrc: KeertiSinghImg,
      title: 'Keerti Singh',
      text: 'Co-founder & VP-Growth',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/keerti25/"
    },
    {
      id: 28,
      imgSrc: SaraswathiVaniImg,
      title: 'Saraswathi Vani',
      text: 'CEO, Creative Director - Dancing Atoms',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/vanibalgam/"
    },
    {
      id: 28,
      imgSrc: RajAnanthaImg,
      title: 'Raj Anantha',
      text: 'Film Maker',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/raj-anantha/"
    },
    // 10
    {
      id: 29,
      imgSrc: CVRaoImg,
      title: 'CV Rao',
      text: 'Chief Technology Officer at Annapurna Studios Private Limited',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/c-v-rao-82128a33/"
    },
    {
      id: 30,
      imgSrc: AnoopAKImg,
      title: 'Anoop A K',
      text: 'CIO - BOT VFX',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/anoopak/"
    },
    {
      id: 31,
      imgSrc: RajanNavaniImg,
      title: 'Rajan Navani',
      text: 'Vice Chairman & Managing Director at JetLine Group of Companies',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/rajan-navani/"
    },
    {
      id: 32,
      imgSrc: ShrutiVermaImg,
      title: 'Shruti Verma',
      text: 'Senior Business Development Lead - India/MEA, APAC',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/shruti-verma-96b2a54/"
    },
    // 11
    {
      id: 32,
      imgSrc: BSSrinivasImg,
      title: 'B.S. Srinivas',
      text: 'Educationist & Mediapreneur - ABAI | Arena Animation | VedAtma Animation Studios',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/srinivas-sribhakta-11299a14/"
    },
    {
      id: 32,
      imgSrc: RasoolElloreImg,
      title: 'Rasool Ellore',
      text: 'Cinematographer',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/rasool-ellore-3a6b862/"
    },
    {
      id: 32,
      imgSrc: PrashanthBuyyalaImg,
      title: 'Prashanth Buyyala',
      text: 'CEO, Investor',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/pbuyyala/"
    },
    {
      id: 32,
      imgSrc: CharuviPAgrawalImg,
      title: 'Charuvi P. Agrawal',
      text: 'Director and Animation Filmmaker',
      status: LinkdianImg,
      link:"https://www.linkedin.com/in/charuvi-agrawal/"
    },
  ]
  return (
    <div>
      <Menu />
      <div className='indiaJoy__speakers__wrapper'>
        <h2 data-aos="fade-right" data-aos-delay="100">PAST Speakers</h2>
        <div className='indiaJoy__speakers__container'>
          {sData.map(item => (
            <div key={item?.id} className='indiaJoy__speakers__section animation__for__profile1' data-aos="fade-down" data-aos-delay="100">
              <div>
              <img src={item?.imgSrc} alt='' className='indiaJoy__animate' loading="lazy" />
              <h3>{item?.title}</h3>
              <p>{item?.text}</p>
              </div>
              <div className="speaker_link">
              <a href={item?.link} target="_blank" rel="noopener noreferrer">
                <img src={item?.status} alt='' className='indiaJoy__footer__icons' loading="lazy"/>
              </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BuyTicket />
      <Footer />
    </div>
  )
}
