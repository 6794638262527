import React from 'react';
import Menu from '../Home/menu';
import { BuyTicket } from '../Contact/buyTicket';
import { Footer } from '../Home/footer';

export const Register = () => {
  return (
    <div>
        <Menu />
        <div className='indiaJoy__comingSoon__wraper'>
            <div className='indiaJoy__comingSoon__container' data-aos="fade-down" data-aos-delay="100">
                <h2>Registration will <br/>
                Open soon</h2>
            </div>
        </div>
        <Footer />
    </div>
  )
}
