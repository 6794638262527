import React from 'react';

export const Count = () => {
  return (
    <div className='indiaJoy__count__wrapper'>
        <div className='indiaJoy__count__container'>
         <div className='indiaJoy__count__section'>
            <div className='indiaJoy__count__details' data-aos="fade-right" data-aos-delay="100">
                <h2>30k</h2>
                <p>Visitors</p>
            </div>
            <div className='indiaJoy__count__details' data-aos="fade-right" data-aos-delay="200">
                <h2>500+</h2>
                <p>Companies</p>
            </div>
            <div className='indiaJoy__count__details' data-aos="fade-left" data-aos-delay="200">
                <h2>10+</h2>
                <p>Countries</p>
            </div>
            <div className='indiaJoy__count__details' data-aos="fade-left" data-aos-delay="100">
                <h2>100+</h2>
                <p>Investors and<br/> publishers</p>
            </div>
         </div>
        </div>
    </div>
  )
}
