import React from 'react';
import Imageslider from './slider';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import slide1 from '../../Assets/images/slider-1.webp';
import slide2 from '../../Assets/images/slider-2.webp';
import slide3 from '../../Assets/images/slider-3.webp';
import slide4 from '../../Assets/images/slider-4.webp';
import slide5 from '../../Assets/images/slider-5.webp';
import slide6 from '../../Assets/images/slider-6.webp';
import slide7 from '../../Assets/images/slider-7.webp';
import galleryImg1 from '../../Assets/images/galleryImages/galleryImg1.png';
import galleryImg2 from '../../Assets/images/galleryImages/galleryImg2.webp';
import galleryImg3 from '../../Assets/images/galleryImages/galleryImg3.png';
import galleryImg4 from '../../Assets/images/galleryImages/galleryImg4.webp';
import galleryImg5 from '../../Assets/images/galleryImages/galleryImg5.webp';
import galleryImg6 from '../../Assets/images/galleryImages/galleryImg6.webp';
import galleryImg7 from '../../Assets/images/galleryImages/galleryImg7.webp';
import galleryImg8 from '../../Assets/images/galleryImages/galleryImg8.webp';
import galleryImg9 from '../../Assets/images/galleryImages/galleryImg9.webp';
import galleryImg10 from '../../Assets/images/galleryImages/galleryImg10.webp';
import galleryImg11 from '../../Assets/images/galleryImages/galleryImg11.webp';
import galleryImg12 from '../../Assets/images/galleryImages/galleryImg12.webp';
import galleryImg13 from '../../Assets/images/galleryImages/galleryImg13.webp';
import galleryImg14 from '../../Assets/images/galleryImages/galleryImg14.webp';
import galleryImg15 from '../../Assets/images/galleryImages/galleryImg15.webp';
import galleryImg16 from '../../Assets/images/galleryImages/galleryImg16.webp';
import galleryImg17 from '../../Assets/images/galleryImages/galleryImg17.webp';
import galleryImg18 from '../../Assets/images/galleryImages/galleryImg18.webp';
import galleryImg19 from '../../Assets/images/galleryImages/galleryImg19.webp';
import galleryImg20 from '../../Assets/images/galleryImages/galleryImg20.webp';
import galleryImg21 from '../../Assets/images/galleryImages/galleryImg21.webp';
import galleryImg22 from '../../Assets/images/galleryImages/galleryImg22.webp';
import galleryImg23 from '../../Assets/images/galleryImages/galleryImg23.svg';
import galleryImg24 from '../../Assets/images/galleryImages/galleryImg24.svg';
import galleryImg25 from '../../Assets/images/galleryImages/galleryImg25.svg';
import galleryImg26 from '../../Assets/images/galleryImages/galleryImg26.svg';
import galleryImg27 from '../../Assets/images/galleryImages/galleryImg27.svg';
import galleryImg28 from '../../Assets/images/galleryImages/galleryImg28.svg';

export const Gallery = () => {
    const sliderImages = [
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7
      ];
      const galleryImages = [
        {
          original: galleryImg1,
          thumbnail: galleryImg1,
        },{
            original: galleryImg2,
            thumbnail: galleryImg2,
          },{
            original: galleryImg3,
            thumbnail: galleryImg3,
          },{
            original: galleryImg4,
            thumbnail: galleryImg4,
          },{
            original: galleryImg5,
            thumbnail: galleryImg5,
          },{
            original: galleryImg6,
            thumbnail: galleryImg6,
          },{
            original: galleryImg7,
            thumbnail: galleryImg7,
          },{
            original: galleryImg8,
            thumbnail: galleryImg8,
          },{
            original: galleryImg9,
            thumbnail: galleryImg9,
          },{
            original: galleryImg10,
            thumbnail: galleryImg10,
          },{
            original: galleryImg11,
            thumbnail: galleryImg11,
          },{
            original: galleryImg12,
            thumbnail: galleryImg12,
          },{
            original: galleryImg13,
            thumbnail: galleryImg13,
          },{
            original: galleryImg14,
            thumbnail: galleryImg14,
          },{
            original: galleryImg15,
            thumbnail: galleryImg15,
          },{
            original: galleryImg16,
            thumbnail: galleryImg16,
          },{
            original: galleryImg17,
            thumbnail: galleryImg17,
          },{
            original: galleryImg18,
            thumbnail: galleryImg18,
          },{
            original: galleryImg19,
            thumbnail: galleryImg19,
          },{
            original: galleryImg20,
            thumbnail: galleryImg20,
          },{
            original: galleryImg21,
            thumbnail: galleryImg21,
          },{
            original: galleryImg22,
            thumbnail: galleryImg22,
          },
          {
            original: galleryImg23,
            thumbnail: galleryImg23,
          },
          {
            original: galleryImg24,
            thumbnail: galleryImg24,
          },
          {
            original: galleryImg25,
            thumbnail: galleryImg25,
          },
          {
            original: galleryImg26,
            thumbnail: galleryImg26,
          },
          {
            original: galleryImg27,
            thumbnail: galleryImg27,
          },
          {
            original: galleryImg28,
            thumbnail: galleryImg28,
          }
      ];
  return (
    <div className="background-container">
        <div className="agenda-title indiaJoy__experience__container" data-aos="fade-right" data-aos-delay="100">
            THE INDIAJOY EXPERIENCE
        </div>
        <div className='col-12 row' style={{ display: 'flex', justifyContent: 'center' }}>
        <Imageslider Images={sliderImages} />
        </div>
        <div className="agenda-title indiaJoy__experience__container" data-aos="fade-right" data-aos-delay="100">
            GALLERY
        </div>
        <div className='col-10 row' style={{ display: 'flex', justifyContent: 'center' }}>
           <ImageGallery items={galleryImages} /> 
        </div>
    </div>
  )
}
