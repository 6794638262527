import React from 'react';
import Menu from '../Home/menu';

export const Events = () => {
  return (
    <div>
      <Menu/>
      Events
    </div>
  )
}
